import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Card from '../UI/Card/Card';
import InprogressIcon from '../../Images/Icons/InProgress.svg';
import LockIcon from '../../Images/Icons/Lock.svg';
import DataTable from "react-data-table-component";
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
import classes from './../TrialstatNoto.module.css';
import customStyles from '../customStyles';
import NoData from '../NoData';
import DataTableExtensions from "../ExportTable/index";
import InputTextModal from './InputTextModal';
import { Button } from 'react-bootstrap';
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';

const ClosedTaskDashboard = (props) => {
    const navigate = useNavigate();
    const [edcUserID, setEDCUserID] = useState('');
    const [username, setUserName] = useState('');    
    //const [readType, setReadType] = useState('');
    const [rowData, setData] = useState([]);
    const [notice, setNotice] = useState([]);
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [arrUserStudies, setUserStudies] = useState([]);
    const [arrSourceType, setSourceType] = useState([]);
    const [sourceTypeId, setSourceTypeId] = useState('-1');
    const [studyId, setStudyId] = useState('-1');
    const [filterId, setFilterId] = useState('-1');
    const [arrFilterOptions, setFilterOptions] = useState([]);
    const [filterName, setFilterName] = useState('');

    useEffect(() => {
        getUserID();
        getUserNameAndRoleByID();
        FillFilterOptionList();
    }, []);

    const getUserNameAndRoleByID = () => {
        fetch('api/Users/getUserNameAndRoleByID')
            .then(response => response.json())
            .then(data => {
                setUserName(data.UserName);
                if (sessionStorage.getItem('closedTaskSearchedVal') != '' && sessionStorage.getItem('closedTaskSearchedVal') != null) {
                    let objSerachedVal = JSON.parse(sessionStorage.getItem('closedTaskSearchedVal'));
                    if (objSerachedVal != null) {
                        setQ(objSerachedVal.text);
                        setInputVal(objSerachedVal.text);
                        setQ(objSerachedVal.text);
                        setFilterId(objSerachedVal.filterId);
                        setStudyId(objSerachedVal.study);
                        setSourceTypeId(objSerachedVal.sourceTypeId);
                        document.getElementById('ddlFilterOptions').value = objSerachedVal.filterId;
                        document.getElementById('ddlStudy').value = objSerachedVal.study;
                        document.getElementById('ddlSourceType').value = objSerachedVal.sourceTypeId;
                        fetchData(data.UserName, objSerachedVal.study, objSerachedVal.sourceTypeId);
                    }
                }
                else {
                    fetchData(data.UserName, '-1', -1);      
                }
              
                fetchNotices();
                setCompPermission(data.RoleLabel);
                //setReadType(data.ReadType);
                fillStudyListByEDCUserId();
                FillSourceTypesList('');
                ShowHideDeleteButton();
            });
    };
    const getUserID = () => {
        fetch('api/Users/GetEDCUserID')
            .then(response => response.text())
            .then(data => {
                setEDCUserID(data);
            });
    };
    const setCompPermission = (usrRole) => {
        usrRole = usrRole.trim();
        if (usrRole == "Read-Only")
            setUserPermisssion("RO")
        else if (usrRole == "Database Administrator")
            setUserPermisssion("RO")
        else if (usrRole == "Clinical Project Manager")
            setUserPermisssion("RO")
        else if (usrRole == "Auditor")
            setUserPermisssion("RO")
        else if (usrRole == "Tester")
            setUserPermisssion("RW")
        else if (usrRole == "Management, Image Services" || usrRole == "Image Services Coordinator")
            setUserPermisssion("RW")
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management")
            setUserPermisssion("RO")
        else
            setUserPermisssion("RO")
    }
    const fetchData = (UserName, SId, StId) => {

        fetch('api/ImageService/GetClosedTasks?UserName=' + UserName + '&SId=' + SId + '&StId=' + StId)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }
    const fillStudyListByEDCUserId = () => {
        fetch('api/ImageService/FillClosedTasksStudyListByUserId')
            .then(response => response.json())
            .then(data => {
                setUserStudies(data);
            });
    }
    const FillSourceTypesList = (sid) => {
        fetch('api/ImageService/FillClosedTasksSourceTypesList?SId=' + sid)
            .then(response => response.json())
            .then(data => {
                setSourceType(data);
            });
    }
    
    //const UnLockHandler = (taskID) => {       
    //    RemoveTaskLock(taskID);
    //}
    const selectRef = useRef('');
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');

    const columns = [
        {
            name: "",
            selector: row => row.IST,
            cellExport: row => row.IST,
            width: "2%",
            style: { "color": "red" },
            export: false

        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '4%',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {row.Status.toLowerCase() === "open" && <img className={classes.act_icon}
                        onClick={e => TaskDetailsHandler(row.TaskID)} src={InprogressIcon} alt="Inprogress " />}
                    {(row.Status.toLowerCase() === "locked" && row.LockedBy == edcUserID) && <img className={classes.act_icon} src={LockIcon} alt="Locked" onClick={e => TaskDetailsHandler(row.TaskID)} />}
                    {(row.Status.toLowerCase() === "locked" && row.LockedBy != edcUserID) && <img className={classes.act_icon} src={LockIcon} alt="Locked" onClick={e => TaskDetailsHandler_RO(row.TaskID, false)} />}
                </div>
            ),
            export: false
        },
        {
            name: "Task Id",
            selector: row => row.TaskID,
            cellExport: row => row.TaskID,
            sortable: true,
            center: true,
            width: "5%"
        },
        {
            name: "Stage",
            selector: row => row.Stage,
            cellExport: row => row.Stage,
            sortable: true,
            center: true,
            width: "10%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Stage}</div>
            ),
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.Stage, b.Stage); }
        },
        {
            name: "Source",
            selector: row => row.SourceType,
            cellExport: row => row.SourceType,
            sortable: true,
            center: true,
            width: "7%",
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.SourceType, b.SourceType); }
        },
        {
            name: "Type",
            selector: row => row.Type,
            cellExport: row => row.Type,
            sortable: true,
            center: true,
            width: "5%",
            omit: true
        },
        {
            name: "Status",
            selector: row => row.Status,
            cellExport: row => row.Status,
            sortable: true,
            center: true,
            width: "6%",
            omit: true
        },
        {
            name: "Study",
            selector: row => row.Study,
            cellExport: row => row.Study,
            sortable: true,
            center: true,
            width: "17%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Study}</div>
            ),
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.Study, b.Study); }
        },
        {
            name: "Protocol",
            selector: row => row.Protocol,
            cellExport: row => row.Protocol,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Site",
            selector: row => row.Site,
            cellExport: row => row.Site,
            sortable: true,
            center: true,
            width: "7%",
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.Site, b.Site); }
        },
        {
            name: "Subject Id",
            selector: row => row.SubjectID,
            cellExport: row => row.SubjectID,
            sortable: true,
            center: true,
            width: "11%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.SubjectID}</div>
            ),
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.SubjectID, b.SubjectID); }
        },
        {
            name: "Procedure Id",
            selector: row => row.ProcedureID,
            cellExport: row => row.ProcedureID,
            sortable: true,
            center: true,
            width: '12%',
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.ProcedureID, b.ProcedureID); }
        },
        {
            name: "Visit",
            selector: row => row.Visit,
            cellExport: row => row.Visit,
            sortable: true,
            center: true,
            width: '7%',
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.Visit, b.Visit); }
        },
        {
            name: "Upload Date",
            selector: row => row.ProcedureDate,
            cellExport: row => row.ProcedureDate,
            sortable: true,
            center: true,
            width: '13%',
            sortFunction:  (a, b) => {return props.sortByDateTime(a.ProcedureDate, b.ProcedureDate);}
        },
        {
            name: "Due",
            selector: row => row.Due,
            cellExport: row => row.Due,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Hrs Remaining",
            selector: row => row.HrsRemaining,
            cellExport: row => row.HrsRemaining,
            sortable: true,
            center: true,
            width: '8%',
            omit: true
        },
        {
            name: "LockedBy",
            selector: row => row.LockedBy,
            cellExport: row => row.LockedBy,
            omit: true
        }
    ];

    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != '') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (row[column] != null) {
                        if (searchText.length > 1)
                            return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                        else
                            return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
                    }
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        sessionStorage.setItem('closedTaskSearchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, study: document.getElementById('ddlStudy').value, sourceTypeId: document.getElementById('ddlSourceType').value, text: e.target.value }));       
        setQ(e.target.value);
        setInputVal(e.target.value);
        selectRef.current.resetSelectedValues();
    };    

    const sourceTypeChangeHandler = (e) => {       
        var selStudy = document.getElementById('ddlStudy').value == -1 || document.getElementById('ddlStudy').value == '' ? '' : document.getElementById('ddlStudy').value;
        var selSource = e.target.value == -1 || e.target.value == '' ? '' : e.target.value;
        setSourceTypeId(selSource);
        sessionStorage.setItem('closedTaskSearchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, study: document.getElementById('ddlStudy').value, sourceTypeId: document.getElementById('ddlSourceType').value, text: inputVal }));       
        fetchData(username, selStudy, selSource);       
    }
    
    const studyChangeHandler = (e) => {
        var selStudy = e.target.value == -1 || e.target.value == '' ? '' : e.target.value;
        var selSource = document.getElementById('ddlSourceType').value == -1 || document.getElementById('ddlSourceType').value == '' ? '' : document.getElementById('ddlSourceType').value;
        setStudyId(selStudy);
        sessionStorage.setItem('closedTaskSearchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, study: document.getElementById('ddlStudy').value, sourceTypeId: document.getElementById('ddlSourceType').value, text: inputVal }));
        FillSourceTypesList(selStudy);
        fetchData(username, selStudy, selSource);
    }

    const conditionalRowStyles = [
        {
            when: row => row.HrsRemaining < 0,
            style: {
                color: 'Red'
            }
        }
    ];
    const PreventFilters = () => {
        sessionStorage.setItem('closedTaskSearchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, study: document.getElementById('ddlStudy').value, sourceTypeId: document.getElementById('ddlSourceType').value, text: inputVal }));
    };
    const TaskDetailsHandler = (taskId) => {
        PreventFilters();
        if (userPermission != 'RO')
            AddTaskLock(taskId);
        else {            
            navigate('/ImgTaskDetails',
                {
                    state: {
                        UserPermission: userPermission,
                        canUnlok: true,
                        taskId: taskId,
                        locNav: 'ctd'// Closed task Dashboard
                    }
                });
        }
    };
    const TaskDetailsHandler_RO = (taskId, flag) => {
        PreventFilters();
        navigate('/ImgTaskDetails',
            {
                state: {
                    UserPermission: 'RO',
                    canUnlok: flag,
                    taskId: taskId,
                    locNav: 'ctd'// Closed task Dashboard
                }
            });
    };

    function getDateTime() {
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var hour = now.getHours();
        var minute = now.getMinutes();
        var second = now.getSeconds();
        if (month.toString().length == 1) {
            month = '0' + month;
        }
        if (day.toString().length == 1) {
            day = '0' + day;
        }
        if (hour.toString().length == 1) {
            hour = '0' + hour;
        }
        if (minute.toString().length == 1) {
            minute = '0' + minute;
        }
        if (second.toString().length == 1) {
            second = '0' + second;
        }
        var dateTime = year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second;
        return dateTime;
    }
    const AddTaskLock = (taskId) => {
        var currentTime = getDateTime();
        fetch('api/ImageService/AddTaskLock?TaskID=' + taskId + '&LockedDate=' + currentTime, { method: "POST" })
            .then(response => response.json())
            .then(data => {
                navigate('/ImgTaskDetails',
                    {
                        state: {
                            UserPermission: userPermission,
                            canUnlok: true,
                            taskId: taskId,
                            locNav: 'ctd'// Closed task Dashboard
                        }
                    });
            });
    };

    const fetchNotices = () => {
        fetch('api/ImageService/GetNotices')
            .then(response => response.json())
            .then(data => {
                setNotice(data);
            });
    };

    const FillFilterOptionList = (filterId) => {
        fetch('api/ImageService/FillFilterOptionList?FilterFor=IMGClosedTask')
            .then(response => response.json())
            .then(data => {
                setFilterOptions(data);
                if (typeof filterId != 'undefined') {
                    sessionStorage.setItem('closedTaskSearchedVal', JSON.stringify({ filterId: filterId, study: document.getElementById('ddlStudy').value, taskStageId: -1, sourceTypeId: document.getElementById('ddlSourceType').value, text: inputVal }));
                    setFilterId(filterId);
                    var e = document.getElementById("ddlFilterOptions");
                    setFilterName(e.options[e.selectedIndex].text);
                }
                ShowHideDeleteButton();
            });
    }

    const filterChangeHandler = (e) => {
        if (e.target.value == -1) {
            setFilterId(-1);
            setStudyId('-1');
            setSourceTypeId(-1);
            setInputVal('');
            setQ('');
            
            sessionStorage.setItem('closedTaskSearchedVal', JSON.stringify({ filterId: -1, study: -1, sourceTypeId: -1, text: '' }));
            fetchData(username, '-1', -1);       
            setFilterName('');
        }
        else {
            setFilterName(e.target.options[e.target.selectedIndex].text);
            setFilterId(e.target.value);
            FetchFilterOptionsDetails(e.target.value);
        }        
        ShowHideDeleteButton();
    }
    const FetchFilterOptionsDetails = (filterId) => {
        fetch('api/ImageService/GetFilterOptionsDetails?filterId=' + filterId)
            .then(response => response.json())
            .then(data => {
                SetFilterOptionsDetail(data);
            });
    }
    function SetFilterOptionsDetail(data) {
        sessionStorage.setItem('closedTaskSearchedVal', JSON.stringify({ filterId: document.getElementById('ddlFilterOptions').value, study: data.StudyId, sourceTypeId: data.SourceTypeId, text: data.SearchText }));
        setStudyId(data.StudyId);
        setSourceTypeId(data.SourceTypeId);
        setInputVal(data.SearchText);
        setQ(data.SearchText);
        fetchData(username, data.StudyId, data.SourceTypeId);
    }

    const DeleteFilterHandler = (event) => {
        var fiDDL = document.getElementById("ddlFilterOptions");
        var fiId = fiDDL.value;
        //var fiName = fiDDL.options[fiDDL.selectedIndex].text;

        var con = confirm('Are you sure, you want to delete "' + filterName + '" filter?');
        if (con == true) {            
            if (fiId == -1) {
                alert('Please check the filter selection.');
                fiDDL.focus();
                return false;
            }

            let objFilterOptionsModel = {
                FilterId: filterId,
                Name: filterName,
                StudyId: studyId,
                SourceTypeId: sourceTypeId,
                SearchText: inputVal,
                FilterFor: "delete"
            };

            fetch('api/ImageService/SaveFilterOptions', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objFilterOptionsModel)
            }).then(r => r.json()).then(res => {
                if (res == -1 || res == -2) {
                    alert('Filter options not deleted, Something went wrong.');
                }
                if (res > 0) {
                    setFilterId(-1);
                    setStudyId('-1');
                    setSourceTypeId(-1);
                    setInputVal('');
                    setQ('');

                    sessionStorage.setItem('closedTaskSearchedVal', JSON.stringify({ filterId: -1, study: -1, sourceTypeId: -1, text: '' }));
                    fetchData(username, '-1', -1); 
                    FillFilterOptionList();
                    setFilterName('');
                }
            })            
        }       
    }

    function ShowHideDeleteButton() {       
        let selFilterVal = document.getElementById('ddlFilterOptions').value;
        if (selFilterVal == -1 || selFilterVal == '') {
            document.getElementById('btnDelete').style.display = 'none';
        }
        else {
            document.getElementById('btnDelete').style.display = '';
        }
    }
    return (
        <Card>

            <Row className="mt-2 mb-2" >
                <Col md={2}>
                    <SelectList id='ddlFilterOptions' dataSource={arrFilterOptions} onChangeHandler={filterChangeHandler} value={filterId} />
                </Col>
                <Col md={3}>
                    <SelectList id='ddlStudy' dataSource={arrUserStudies} onChangeHandler={studyChangeHandler} value={studyId} />
                </Col>
                <Col md={2}>
                    <SelectList id='ddlSourceType' dataSource={arrSourceType} onChangeHandler={sourceTypeChangeHandler} value={sourceTypeId} />
                </Col>
                <Col md={3}>
                    <div className="input-group" style={{ "float": "right" }}>
                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                    </div>
                </Col>
                <Col md={2}>
                    <div style={{ "float": "right"}}>
                        <InputTextModal btn="true" text="Save" studyId={studyId} sourceTypeId={sourceTypeId} searchText={inputVal} filterId={filterId} filterName={filterName} fillFilterOptionList={FillFilterOptionList} filterFor="IMGClosedTask" />
                        <Button id="btnDelete" className="btn btn-md btn-danger" style={{ marginLeft: "10px" }} onClick={DeleteFilterHandler}> Delete </Button>
                    </div>
                </Col>
            </Row>
            <div className="tableContainer ">
                {search(rowData).length > 0 ?
                    <DataTableExtensions columns={columns} data={search(rowData)} filter={false} fileName="ImageServiceDashboard">
                        <DataTable
                            className="table table-striped table-bordered table-hover"
                            conditionalRowStyles={conditionalRowStyles}
                            customStyles={customStyles}
                            striped
                            pagination
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[20, 50, 100]}
                        />
                    </DataTableExtensions> : <NoData headers={columns} />}
            </div>
        </Card>
    );
};
export default ClosedTaskDashboard;