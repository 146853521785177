import React, { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Multiselect from 'multiselect-react-dropdown';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import ManageAmendmentModal from './ManageAmendmentModal';
import StudyStatusModal from './StudyStatusModal';
import ViewStudyModal from './ViewStudyModal';
import EditStudyModal from './EditStudyModal';
import customStyles from '../customStyles';
import NoData from '../NoData';
import classes from './../TrialstatNoto.module.css';
import AdjudicationSettingsModal from "./AdjudicationSettings";
//import Dropdown from 'react-bootstrap/Dropdown'; 
import Button1 from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import OutcomeNotificationModal from './OutcomeNotificationModal';
import ToggleButton from '../UI/Button/ToggleButton';

const Studies = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [rowData, setData] = useState([]);
    const [isSaved, setIsSaved] = useState(false);
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [manageSitePermission, setmanageSitePermission] = useState('RO'); //RO, RW, NA
    const [envVar, setEnvVar] = useState('PROD'); //PROD, UAT, DEV
    const selectRef = useRef('');
    const navigate = useNavigate();
    const [status, setStatus] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [userRole, setUserRole] = useState('');
    //const [isOutcomeConfig, setIsOutcomeConfig] = useState(true);
    //const [isEmailNotification, setIsEmailNotification] = useState(true);
    //const [isSiteDashboad, setIsSiteDashboad] = useState(true);
    const [filterType, setFilterType] = useState('');
    const [isOcReadOnly, setIsOcReadOnly] = useState(false);
    const [sponsorsList, setSponsorsList] = useState([{}]);
    const [sponsorId, setSponsorId] = useState(-1);
    const onClickHandler = () => {
        navigate('/NewStudy', {
            action: 'Add',
        });
    }

    const onClickRefershHandler = () => {
        setQ('');
        setInputVal('');
        setSponsorId(-1);
        fetchData();
        sessionStorage.setItem('searchedStudyVal', '');
    }
    useEffect(() => {
        FillStudyStatusData();
        fetchData();
        getUserRole();
        fetchSponsorsList();
    }, [isSaved])

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                envVariable(role);
                setUserRole(role);
            });
    };

    const fetchSponsorsList = () => {
        fetch('api/Study/GetSponsors')
            .then(response => response.json())
            .then(data => {
                setSponsorsList(data);
            });
    }
    const envVariable = (role) => {
        fetch('api/AppSettings/EnvVariable')
            .then(res => res.text())
            .then(varData => {
                setCompPermission(role, varData);
            });
    }
    const setCompPermission = (role, varData) => {
        if (varData == "UAT") {
            setEnvVar(varData)
            setUserPermisssion("RO")
        }
        else {
            if (role == "Read-Only")
                setUserPermisssion("RO")
            else if (role == "Database Designer" || role == "Super User")
                setUserPermisssion("RW")
            else if (role == "Tester")
                setUserPermisssion("RO")
            else if (role == "Clinical Data Manager" || role == "Manager, Clinical Data Management" || role == "Clinical Project Manager") {
                setUserPermisssion("RO")
                setmanageSitePermission("RW")
                setIsOcReadOnly(true);
            }
            else
                setUserPermisssion("NA")
        }
    }

    const onManageSiteHandler = (StudyID, StudyProtocolId, event) => {
        navigate('/ManageSites', { state: { studyId: StudyID, studyProtocolId: StudyProtocolId } });
    }

    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');

    const columns = [
        {
            name: "Study Name",
            selector: row => row.StudyName,
            cellExport: row => row.StudyName,
            sortable: true,
            center: true,
            width: "17%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.StudyName}</div>
            )
        },
        {
            name: "Protocol",
            selector: row => row.Protocol,
            cellExport: row => row.Protocol,
            sortable: true,
            center: true,
            width: "11%"
        },
        {
            name: "Study Protocol ID",
            selector: row => row.StudyProtocolID,
            cellExport: row => row.StudyProtocolID,
            omit: true
        },
        {
            name: "Protocol Version",
            selector: row => row.ProtocolVersion,
            cellExport: row => row.ProtocolVersion,
            sortable: true,
            center: true,
            width: "8%"
        },
        {
            name: "Sponsor",
            selector: row => row.Sponsor,
            cellExport: row => row.Sponsor,
            sortable: true,
            center: true,
            width: "11%"
        },
        {
            name: "Status",
            selector: row => row.Status,
            cellExport: row => row.Status,
            sortable: true,
            center: true,
            width: "10%"
        },
        {
            name: "EDCID",
            selector: row => row.EDCID,
            cellExport: row => row.EDCID,
            omit: true
        },
        {
            name: "Environment",
            selector: row => row.Environment,
            cellExport: row => row.Environment,
            omit: true
        },
        {
            name: "Study Tags",
            selector: row => row.StudyTags,
            cellExport: row => row.StudyTags,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "ConfigEnabled",
            selector: row => row.OCConfigEnabled,
            cellExport: row => row.OCConfigEnabled,
            sortable: false,
            center: true,
            omit: true
        },
        {
            name: "OCEmailNotification",
            selector: row => row.OCEmailNotification,
            cellExport: row => row.OCEmailNotification,
            sortable: false,
            center: true,
            omit: true
        },
        {
            name: "OCDashboardNotification",
            selector: row => row.OCDashboardNotification,
            cellExport: row => row.OCDashboardNotification,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '33%',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
                'justify-content': 'left'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {/* Read Write Permission*/}
                    {row.EDCID != "waiting" && <div style={{ "padding": "5px" }}>
                        {userPermission == "RW" && ((row.EDCID == "0" || row.EDCID == "") || (userRole == 'Super User')) &&
                            <EditStudyModal style={{ "margin": "5px" }} studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} text="Edit" action="Edit" fetchData={fetchData} userRole={userRole}></EditStudyModal>}

                        {userPermission == "RW" && <ViewStudyModal style={{ "margin": "5px" }} studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} text="View" action="View" userRole=""></ViewStudyModal>}

                        {(userPermission == "RW" || envVar == "UAT" || (userRole == 'Clinical Data Manager' || userRole == 'Manager, Clinical Data Management')) &&
                            <AdjudicationSettingsModal disabled={userPermission == 'RO' ? true : false} style={{ "margin": "5px" }} text="Adjudication" studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} action="newAdjudication" fetchData={fetchData}></AdjudicationSettingsModal>}

                        {userPermission == "RW" && (row.EDCID != "0" && row.EDCID != "") &&
                            <ManageAmendmentModal style={{ "margin": "5px" }} title="Manage study" text="Study" studyId={row.StudyID} studyName={row.StudyName} protocolVersion={row.Protocol} action="createamendment" fetchData={fetchData}></ManageAmendmentModal>}

                        {userPermission == "RW" && <StudyStatusModal style={{ "margin": "5px" }} title="Update status" text="Status" studyName={row.StudyName} protocolVersion={row.Protocol} statusData={statusData} studyStatusId={row.StudyStatusId} studyId={row.StudyID} fetchData={fetchData} ></StudyStatusModal>}

                        {userPermission == "RW" && (row.EDCID != "0" && row.EDCID != "") &&
                            <button style={{ "margin": "5px" }} title="Manage site" onClick={() => onManageSiteHandler(row.StudyID, row.StudyProtocolID, event)} className="btn btn-info">Site</button>}

                        {userPermission == "RW" && row.DeployedOnProd == "N" && <button style={{ "margin": "5px" }} onClick={() => handleDeleteStudy(row.StudyID, event)} className="btn btn-danger"> Delete </button>}

                        {/* Read Only Permission or Clinical Data Manager */}
                        {userPermission == "RO" && <ViewStudyModal style={{ "margin": "5px" }} studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} text="View" action="View" userRole=""></ViewStudyModal>}
                        {(userPermission == "RO" || manageSitePermission == "RW") && (row.EDCID != "0" && row.EDCID != "") &&
                            <button style={{ "margin": "5px" }} title="Manage site" onClick={() => onManageSiteHandler(row.StudyID, row.StudyProtocolID, event)} className="btn btn-info">Site</button>}
                        {(userPermission == "RW" || isOcReadOnly) && <ButtonGroup>
                            <OutcomeNotificationModal text="Outcome" refreshData={fetchData} studyId={row.StudyID} setIsOutcomeConfig={row.OCConfigEnabled == "Y" ? "yes" : "no"} setIsEmailNotification={row.OCEmailNotification == "Y" ? "yes" : "no"} setIsSiteDashboad={row.OCDashboardNotification == "Y" ? "yes" : "no"} isOCReadOnly={isOcReadOnly} ></OutcomeNotificationModal>
                            <DropdownButton as={ButtonGroup} id="bg-nested-dropdown" variant="secondary" title="">
                                <Dropdown.Item eventKey="1" disabled={row.OCConfigEnabled == "N" ? true : false} onClick={() => OnClickOutComesMapping(row.StudyID, row.StudyProtocolID, event)} >Outcomes Mapping</Dropdown.Item>
                                <Dropdown.Item eventKey="2" disabled={row.OCEmailNotification == "N" ? true : false} onClick={() => onOutcomeEmailHandler(row.StudyID, row.StudyProtocolID, event)} >Outcomes Email Template</Dropdown.Item>
                                <Dropdown.Item eventKey="3" disabled={row.OCEmailNotification == "N" ? true : false} onClick={() => OutcomeGlobalEmailHandler(row.StudyID, row.StudyName, event)} >Outcomes Global Email</Dropdown.Item>
                            </DropdownButton>
                        </ButtonGroup>}
                    </div>
                    }
                    {row.EDCID == "waiting" && <div style={{ "padding": "5px" }}>
                        {<ViewStudyModal style={{ "margin": "5px" }} studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} text="View" action="View" userRole=""></ViewStudyModal>}
                        {<StudyStatusModal style={{ "margin": "5px" }} title="Update status" text="Status" studyName={row.StudyName} protocolVersion={row.Protocol} statusData={statusData} studyStatusId={row.StudyStatusId} studyId={row.StudyID} fetchData={fetchData} ></StudyStatusModal>}
                    </div>
                    }

                </div>
            )
        },
        {
            name: "Favourite",
            width: '7%',
            selector: row => row.IsFavoriteStudy,
            cell: (row) => (<div style={{ "padding": "5px" }}>
                <ToggleButton id={row.StudyID} onToggleChange={() => onToggleChange(event, row.StudyID)} checked={row.IsFavoriteStudy == 'Y' ? true : false} />
            </div>),
            center: true,
        }
    ];

    const onToggleChange = (e, studyID) => {
        var isFavorite = e.target.checked;
        fetch('api/Study/SetFavoriteFlag?studyId=' + studyID + '&isFavorite=' + isFavorite, { method: 'post' })
            .then(response => response.text())
            .then(data => {
                if (data == 'success')
                    //setIsSaved(true);
                    fetchData();
                else
                    alert('Error while setting selected study as favorite.')
            });
    };

    const OnClickOutComesMapping = (StudyID, StudyProtocolId, event) => {
        navigate('/OutcomeMappingList', { state: { studyId: StudyID, studyProtocolId: StudyProtocolId } });
    };

    const fetchData = () => {
        fetch('api/Study/GetStudies').then(response => response.json())
            .then(data => {
                setData(data);
                if (sessionStorage.getItem('searchedStudyVal') != '' && sessionStorage.getItem('searchedStudyVal') != null) {
                  
                    let objSerachedVal = JSON.parse(sessionStorage.getItem('searchedStudyVal'));
                    if (objSerachedVal != null) {
                        if (objSerachedVal.filterType == 'status') {
                            setFilterType('status');
                            setQ(objSerachedVal.status.join(','));
                        }
                        else {
                            setFilterType('column');
                            setQ(objSerachedVal.searchText);
                        }
                        if (objSerachedVal.filterType == 'status') {
                            setQ(objSerachedVal.status.toString());
                            setInputVal();
                        }
                        else {
                            if ((objSerachedVal.filterSponsor != -1 || objSerachedVal.filterSponsor == null) && objSerachedVal.searchText == '') {
                                setSponsorId(objSerachedVal.filterSponsor);
                                setQ(objSerachedVal.sponsorText);
                                setInputVal('');
                            }
                            else if ((objSerachedVal.filterSponsor != -1 || objSerachedVal.filterSponsor == null)  && objSerachedVal.searchText != '') {
                                setQ(objSerachedVal.searchText);
                                setInputVal(objSerachedVal.searchText);
                            }
                        }
                        sessionStorage.setItem('searchedStudyVal', JSON.stringify({ status: objSerachedVal.status, filterSponsor: objSerachedVal.filterSponsor, sponsorText: objSerachedVal.sponsorText, searchText: objSerachedVal.searchText, filterType: objSerachedVal.filterType }));
                    }
                }
            });
        setIsSaved(false);
    }

    function search(rows) {
        var filteredColumns = [];
        if (q != '') {
            filteredColumns = ['StudyName', 'Protocol', 'Sponsor', 'Status', 'StudyTags', 'ProtocolVersion'];

            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return filteredColumns.some((column) => {
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setFilterType('column');
        setQ(e.target.value);
        setInputVal(e.target.value);
        selectRef.current.resetSelectedValues();
        if (search(rowData).length == 1) {
            const collection = document.getElementsByClassName("rdt_Table");
            collection[0].setAttribute("style", "min-height: 200px!important");
        }
        setSponsorId(-1);
        sessionStorage.setItem('searchedStudyVal', JSON.stringify({ status: null, filterSponsor: null, sponsorText: null, searchText: document.getElementById('txtSearch').value, filterType: 'column' }));
    }

    const handleDeleteStudy = (studyId, event) => {
        var con = confirm('Are you sure, you want to delete study?');
        if (con == true) {
            DeleteStudy(studyId);
        }
    };

    const DeleteStudy = (studyId) => {
        let objStudyModel = {
            StudyID: studyId,
            UserName: EDCUserID,
            Alert: 'X'
        };

        fetch('api/Study/DeleteStudy', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objStudyModel)
        }).then(r => r.json()).then(res => {
            if (res > 0) {
                fetchData();
            }
        })
    };

    const FillStudyStatusData = () => {
        fetch('api/Study/FillStudyStatusData')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: 0, Name: '' }];
                let arr1 = new Array();
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                    arr1.push(data[iCount].Name);
                }
                setStatus(arr1);
                setStatusData(arr);
            });
    }

    const onOutcomeEmailHandler = (StudyID, StudyProtocolId, event) => {
        navigate('/OutcomeEmailDesigner', { state: { studyId: StudyID, studyProtocolId: StudyProtocolId, isOCReadOnly: isOcReadOnly } });
    }

    const OutcomeGlobalEmailHandler = (StudyID, StudyName, event) => {
        navigate('/OutcomeGlobalEmailList', { state: { studyId: StudyID, studyName: StudyName, isOCReadOnly: isOcReadOnly } });
    }

    var FilterSponsorHandler = (e) => {
        setFilterType('column');
        setSponsorId(document.getElementById("ddlSponsors").value);
        let selVal = e.target.value == '-1' ? '' : e.target.value;
        var selDdl = document.getElementById("ddlSponsors");
        let selTxt = e.target.value == '-1' ? '' : selDdl.options[selDdl.selectedIndex].text;

        setQ(selTxt);
        setInputVal('');
        selectRef.current.resetSelectedValues();
        sessionStorage.setItem('searchedStudyVal', JSON.stringify({ status: null, filterSponsor: selVal, sponsorText: selTxt, searchText: '', filterType: 'column' }));
    };

    const customSort = (rows, columnName, direction) => {
        var field = columnName.toString().split('.')[1];
        const multiplier = direction === "asc" ? 1 : -1;

        var arrFavStudy = rows.filter(function (el) {
            return el.IsFavoriteStudy.toLowerCase() == 'y'
        });

        var sortFavStudy = arrFavStudy.sort((a, b) => {
            if (a[field].toLowerCase() < b[field].toLowerCase()) return -1 * multiplier;
            if (a[field].toLowerCase() > b[field].toLowerCase()) return 1 * multiplier;
            return 0;
        });

        var arrNormalStudy = rows.filter(function (el) {
            return el.IsFavoriteStudy.toLowerCase() == 'n'
        });

        var sortNormalStudy = arrNormalStudy.sort((a, b) => {
            if (a[field].toLowerCase() < b[field].toLowerCase()) return -1 * multiplier;
            if (a[field].toLowerCase() > b[field].toLowerCase()) return 1 * multiplier;
            return 0;
        });

        Array.prototype.push.apply(sortFavStudy, sortNormalStudy);
        return sortFavStudy;
    };

    return <Card>
        <Row>
            <Col md={3} className="form-inline mb-2">
                <Multiselect
                    placeholder="Status"
                    isObject={false}
                    onKeyPressFn={function noRefCheck() { }}
                    onRemove={function noRefCheck() {
                        let setItem = selectRef.current.getSelectedItems()
                        setQ(setItem.toString());
                        sessionStorage.setItem('searchedStudyVal', JSON.stringify({ status: setItem, filterSponsor: -1, sponsorText: '', searchText: '', filterType: 'status' }));
                    }}
                    onSearch={function noRefCheck() { }}
                    onSelect={function noRefCheck() {
                        setFilterType('status');
                        setSponsorId(-1);
                        setInputVal('');
                        let setItem = selectRef.current.getSelectedItems();
                        setQ(setItem.toString());
                        sessionStorage.setItem('searchedStudyVal', JSON.stringify({ status: setItem, filterSponsor: -1, sponsorText: null, searchText: '', filterType: 'status' }));
                    }}
                    options={status}
                    ref={selectRef}
                    selectedValues={filterType == 'status' && q != '' ? q.split(',') : null}                   
                />
            </Col>
            <Col md={1} style={{ paddingRight: "0px", width: "3%" }}>
                {/* <b>OR</b>*/}
            </Col>
            <Col md={2}>
                <SelectList dataSource={sponsorsList} id="ddlSponsors" onChangeHandler={FilterSponsorHandler} value={sponsorId} />
            </Col>
            <Col md={3}>
                <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" style={{ marginRight: "15px" }} />
            </Col>
            <Col md={1} style={{ paddingLeft: "0px" }}>
                <Button onClick={onClickRefershHandler} className="btn btn-success">Refresh</Button>
            </Col>
            <Col md={2}>
                {userPermission == "RW" && <Button style={{ "float": "right" }} onClick={onClickHandler} className="btn btn-success">Create New Study</Button>}
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                sortFunction={customSort}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}
export default Studies;