import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import classes from '../TrialstatNoto.module.css'

const CRSiteAffiliation = (props) => {

    const navigate = useNavigate();
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);

    const columns = [
        {
            name: "StudyID",
            selector: row => row["StudyID"],
            omit: true
        },
        {
            name: "Code",
            selector: row => row["StudyCode"],
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Protocol",
            selector: row => row["ProtocolCode"],
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Sponsor",
            selector: row => row.Sponsor,
            sortable: true,
            center: true,
            width: "35%"
        },
        {
            name: "Name",
            selector: row => row.StudyName,
            sortable: true,
            center: true,
            width: "35%",
            cell: (row) => (
                <div className={classes.textWrap} > {row.StudyName}</div>
            )
        },
        {
            name: "Completed",
            selector: row => row.Completed,
            sortable: true,
            center: true,
            width: "15%",
            conditionalCellStyles: [
                {
                    when: row => row.Completed.toLowerCase() == "y",
                    style: {
                        backgroundColor: 'green',
                        color: "white",
                        fontWeight:"bold"
                    },
                },
                {
                    when: row => row.Completed.toLowerCase() == "n",
                    style: {
                        backgroundColor: 'red',
                        color: "white",
                        fontWeight: "bold"
                    },
                },
            ],
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width:'15%',
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    <button style={{ "margin": "5px" }} title="CR User" onClick={() => onAssignCR(row.StudyID, row.StudyName, event)} className="btn btn-success">Assign CR</button>
                </div>
            )
        }
    ];

    const onAssignCR = (StudyID, StudyName, event) => {
        navigate('/CRUserList', { state: { studyId: StudyID, studyName: StudyName } });
    }

    const filteredColumns = ['StudyName', 'StudyCode', 'ProtocolCode', 'Sponsor'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
            })
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const fetchCRSiteAffilList = (roleId) => {
        fetch('api/SiteAffiliation/getCRSiteAffilList')
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    }


    useEffect(() => {
        fetchCRSiteAffilList();
    }, []);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={6}>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
            <Col md={6} />
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                sortFunction={props.sortFunctionByTable}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns} />}
        </div>
    </Card>
};

export default CRSiteAffiliation;
