import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import ArrowLeft from '../../Images/Icons/ArrowLeftBorder.svg';
import ArrowRight from '../../Images/Icons/ArrowRightBorder.svg';
import ExternalReceiptModal from "./ExternalReceiptModal";
import customStyles from '../customStyles';
import NoData from '../NoData';
import { Modal } from 'react-bootstrap';
import CopyIcon from '../../Images/Icons/copy.svg';
import classes from './../TrialstatNoto.module.css';

const AssignStudyModal = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);  
    const [show, setShow] = useState(false);
    const [usrRole, setUsrRole] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => {       
        fetchAvailableStudies();
        fetchUserAssignedStudies();
        setShow(true);
        getUserRole();    
    };

    const [q, setQ] = useState('');

    const [rowAvailableStudy, setAvailableStudy] = useState([]);
    const [rowUserAssignedStudy, setUserAssignedStudy] = useState([]);
    const [inputVal, setInputVal] = useState('');

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setUsrRole(role);
            });
    };

    const columnsAvlStudy = [
        {
            name: "",
            width: "50px",
            cell: (row) => (
                <input
                    type="checkbox"
                    className="chkClassName"
                    onClick={() => handleAvailableStudySelectRow(row.StudyID, event)}
                    style={{ height: '20px', width: '20px' }}
                />
            )
        },
        {
            name: "Study",
            selector: row => row.StudyName,
            cellExport: row => row.StudyName,
            sortable: true,
            center: true
        },
        {
            name: "Description",
            selector: row => row.StudyDesc,
            cellExport: row => row.StudyDesc,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
        }
    ];

    const columnsAgnStudy = [
        {
            name: "",
            width: "50px",
            cell: (row) => (
                <input
                    type="checkbox"
                    className="chkRClassName"
                    onClick={() => handleAssignedStudySelectRow(row.StudyID, event)}
                    style={{ height: '20px', width: '20px' }}
                />
            )
        },
        {
            name: "Study",
            selector: row => row.StudyName,
            cellExport: row => row.StudyName,
            sortable: true,
            center: true
        },
        {
            name: "Description",
            selector: row => row.StudyDesc,
            cellExport: row => row.StudyDesc,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
        }
    ];    

    const filteredColumns = ['StudyName', 'StudyDesc'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
            })
        })
    }

    var arrAvlStudyId = new Array();
    const handleAvailableStudySelectRow = (state, event) => {
        if (event.target.checked == true) {
            arrAvlStudyId.push(state);
        } else {
            arrAvlStudyId = arrAvlStudyId.filter(item => item !== state)
        }
    };

    var arrAssStudyId = new Array();
    const handleAssignedStudySelectRow = (state, event) => {
        if (event.target.checked == true) {
            arrAssStudyId.push(state);
        }
        else {
            arrAssStudyId = arrAssStudyId.filter(item => item !== state)
        }
    };

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const AssignStudyToUser = (event, arrAvlStudyId) => {
        let objUserStudyAssignments = {
            Username : EDCUserID,
            EDCUserID: props.edcUserID,
            listStudyID: new Array()
        }

        for (var iCount = 0; iCount < arrAvlStudyId.length; iCount++) {
            objUserStudyAssignments.listStudyID.push(arrAvlStudyId[iCount]);
        }

        fetch('api/Users/AssignStudyToUser', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUserStudyAssignments)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Study is not assigned to user, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchAvailableStudies();
                fetchUserAssignedStudies();
            }
        })
        return true;
    };

    const RemoveStudyFromUser = (event, arrAssStudyId) => {
        let objUserStudyAssignments = {
            Username: EDCUserID,
            EDCUserID: props.edcUserID,
            listStudyID: new Array()
        }

        for (var iCount = 0; iCount < arrAssStudyId.length; iCount++) {
            objUserStudyAssignments.listStudyID.push(arrAssStudyId[iCount]);
        }

        fetch('api/Users/RemoveStudyFromUser', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUserStudyAssignments)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Study is not removed from user, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchAvailableStudies();
                fetchUserAssignedStudies();
            }
        })
        return true;
    };

    const handleRightShift = (e) => {
        let arrChk = document.getElementsByClassName('chkClassName');
        for (var iCount = 0; iCount < arrChk.length; iCount++) {
            arrChk[iCount].checked = false;
        }

        if (arrAvlStudyId.length == 0) {
            alert('Please select study(s) to assign to user.');
        }
        else {
            AssignStudyToUser(e, arrAvlStudyId);
        }
    };

    const handleLeftShift = (e) => {
        let arrChk = document.getElementsByClassName('chkRClassName');
        for (var iCount = 0; iCount < arrChk.length; iCount++) {
            arrChk[iCount].checked = false;
        }

        if (arrAssStudyId.length == 0) {
            alert('Please select study(s) to revoke from user.');
        }
        else {
            RemoveStudyFromUser(e, arrAssStudyId);
        }
    };

    const SubmitData = (e) => {
    };

    const HandleClose = (e) => {
    };


    const fetchAvailableStudies = () => {
        fetch('api/Users/GetAvailableStudies?edcUserID=' + props.edcUserID)
            .then(response => response.json())
            .then(data => {
                setAvailableStudy(data);
            });
    }

    const fetchUserAssignedStudies = () => {
        fetch('api/Users/GetUserAssignedStudies?edcUserID=' + props.edcUserID)
            .then(response => response.json())
            .then(data => {
                setUserAssignedStudy(data);
            });
    }


    return (
        <>
            <img className="" style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "10px" }} height="34px" width="34px" src={CopyIcon} alt="Add" title="Add Study" onClick={handleShow} />
            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Two} animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{props.username}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <div className="row mt-1">
                            <div className="col-sm-5" style={{ width: "46%" }}>
                                <div className="tableContainer">
                                    <div className="row">
                                        <div className="col-sm-4 d-flex align-items-center" style={{ textAlign: "left" }}>
                                            <h5><strong>Available Study</strong></h5>
                                        </div>
                                    <div className="col-sm-8">
                                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" style={{ marginBottom: "6px" }} />
                                        </div>
                                    </div>
                                {search(rowAvailableStudy).length > 0 ? <DataTable
                                    columns={columnsAvlStudy}
                                    data={search(rowAvailableStudy)}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    sortFunction={props.sortFunctionByTable}
                                    selectableRowsNoSelectAll="false"
                                    highlightOnHover
                                    striped
                                    pagination
                                /> : <NoData headers={columnsAvlStudy}></NoData>}
                                </div>
                            </div>
                            <div className="col-sm-2 d-flex align-items-center justify-content-center" style={{ width: "8%" }}>
                            <table style={{ border: "none", marginTop: "75px" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                        {usrRole != "Read-Only" && <img src={ArrowRight} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt=">>" onClick={handleRightShift} />}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        {usrRole != "Read-Only" && <img src={ArrowLeft} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt="<<" onClick={handleLeftShift} />}
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <div className="col-sm-5" style={{ width: "46%" }}>
                                <div className="tableContainer">
                                    <div className="row">
                                        <div className="col-sm-6 d-flex align-items-center" style={{ textAlign: "left" }}>
                                            <h5><strong>Assigned Study</strong></h5>
                                        </div>
                                        <div className="col-sm-6">
                                           
                                        </div>
                                    </div>
                                {rowUserAssignedStudy.length > 0 ? <DataTable
                                    columns={columnsAgnStudy}
                                    data={rowUserAssignedStudy}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    sortFunction={props.sortFunctionByTable}
                                    selectableRowsNoSelectAll="false"
                                    striped
                                    pagination
                                /> : <NoData headers={columnsAgnStudy}></NoData>}
                                </div>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" className="btn btn-success" onClick={handleClose} style={{display : "none"}} > Submit </Button>
                    <Button variant="primary" className="btn btn-danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AssignStudyModal;