import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import Button from '../UI/Button/Button';
import InputString from '../UI/Control/InputString';
import ArrowLeft from '../../Images/Icons/ArrowLeftBorder.svg';
import ArrowRight from '../../Images/Icons/ArrowRightBorder.svg';
import customStyles from '../customStyles';
import NoData from '../NoData';
import { Modal } from 'react-bootstrap';
import EditIcon from '../../Images/Icons/edit.svg';
import classes from './../TrialstatNoto.module.css';

const AssignSiteModal = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [show, setShow] = useState(false);
    const [usrRole, setUsrRole] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => {
        fetchAvailableSites();
        fetchUserAssignedSites();
        setShow(true);
        getUserRole();
    };

    const [q, setQ] = useState('');

    const [rowAvailableSites, setAvailableSites] = useState([]);
    const [rowUserAssignedSites, setUserAssignedSites] = useState([]);
    const [inputVal, setInputVal] = useState('');

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setUsrRole(role);
            });
    };

    const columnsAvlSites = [
        {
            name: "",
            width: "50px",
            cell: (row) => (
                <input
                    type="checkbox"
                    className="chkClassName"
                    onClick={() => handleAvailableSiteSelectRow(row.StudySiteID, event)}
                    style={{ height: '20px', width: '20px' }}
                />
            )
        },
        {
            name: "Study",
            selector: row => row.StudyCode,
            cellExport: row => row.StudyCode,
            sortable: true,
            center: true
        },
        {
            name: "Site",
            selector: row => row.SiteCode,
            cellExport: row => row.SiteCode,
            sortable: true,
            center: true
        },
        {
            name: "Description",
            selector: row => row.SiteName,
            cellExport: row => row.SiteName,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
        }
    ];

    const columnsAgnSites = [
        {
            name: "",
            width: "50px",
            cell: (row) => (
                <input
                    type="checkbox"
                    className="chkRClassName"
                    onClick={() => handleAssignedSiteSelectRow(row.StudySiteID, event)}
                    style={{ height: '20px', width: '20px' }}
                />
            )
        },
        {
            name: "Study",
            selector: row => row.StudyCode,
            cellExport: row => row.StudyCode,
            sortable: true,
            center: true
        },
        {
            name: "Name",
            selector: row => row.SiteCode,
            cellExport: row => row.SiteCode,
            sortable: true,
            center: true
        },
        {
            name: "Description",
            selector: row => row.SiteName,
            cellExport: row => row.SiteName,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            }
        }
    ];

    const filteredColumns = ['SiteCode', 'SiteName', 'StudyCode'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
            })
        })
    }

    var arrAvlSiteId = new Array();
    const handleAvailableSiteSelectRow = (state, event) => {
        if (event.target.checked == true) {
            arrAvlSiteId.push(state);
        } else {
            arrAvlSiteId = arrAvlSiteId.filter(item => item !== state)
        }
    };

    var arrAssSiteId = new Array();
    const handleAssignedSiteSelectRow = (state, event) => {
        if (event.target.checked == true) {
            arrAssSiteId.push(state);
        }
        else {
            arrAssSiteId = arrAssSiteId.filter(item => item !== state)
        }
    };

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const AssignSiteToUser = (event, arrAvlSiteId) => {
        let objUserSiteAssignments = {
            EDCUserID: props.edcUserID,
            listStudySiteID: new Array()
        }

        for (var iCount = 0; iCount < arrAvlSiteId.length; iCount++) {
            objUserSiteAssignments.listStudySiteID.push(arrAvlSiteId[iCount]);
        }

        fetch('api/Users/AssignSiteToUser', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUserSiteAssignments)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Site is not assigned to user, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchAvailableSites();
                fetchUserAssignedSites();
            }
        })
        return true;
    };

    const RemoveSitesFromUser = (event, arrAssSiteId) => {
        let objUserSiteAssignments = {
            EDCUserID: props.edcUserID,
            listStudySiteID: new Array()
        }

        for (var iCount = 0; iCount < arrAssSiteId.length; iCount++) {
            objUserSiteAssignments.listStudySiteID.push(arrAssSiteId[iCount]);
        }

        fetch('api/Users/RemoveSiteFromUser', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUserSiteAssignments)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Site is not removed from user, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchAvailableSites();
                fetchUserAssignedSites();
            }
        })
        return true;
    };

    const handleRightShift = (e) => {
        let arrChk = document.getElementsByClassName('chkClassName');
        for (var iCount = 0; iCount < arrChk.length; iCount++) {
            arrChk[iCount].checked = false;
        }

        if (arrAvlSiteId.length == 0) {
            alert('Please select site(s) to assign to user.');
        }
        else {
            AssignSiteToUser(e, arrAvlSiteId);
        }
    };

    const handleLeftShift = (e) => {
        let arrChk = document.getElementsByClassName('chkRClassName');
        for (var iCount = 0; iCount < arrChk.length; iCount++) {
            arrChk[iCount].checked = false;
        }

        if (arrAssSiteId.length == 0) {
            alert('Please select site(s) to revoke from user.');
        }
        else {
            RemoveSitesFromUser(e, arrAssSiteId);
        }
    };

    const fetchAvailableSites = () => {
        fetch('api/Users/GetAvailableSites?edcUserID=' + props.edcUserID)
            .then(response => response.json())
            .then(data => {
                setAvailableSites(data);
            });
    }

    const fetchUserAssignedSites = () => {
        fetch('api/Users/GetUserAssignedSites?edcUserID=' + props.edcUserID)
            .then(response => response.json())
            .then(data => {
                setUserAssignedSites(data);
            });
    }


    return (
        <>
            <img className="" style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "10px" }} height="34px" width="34px" src={EditIcon} alt="Add" title="Add Site" onClick={handleShow} />
            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Two} animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{props.username}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-1">
                        <div className="col-sm-5" style={{ width: "46%" }}>
                            <div className="tableContainer">
                                <div className="row">
                                    <div className="col-sm-4 d-flex align-items-center" style={{ textAlign: "left" }}>
                                        <h5><strong>Available Sites</strong></h5>
                                    </div>
                                    <div className="col-sm-8">
                                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" style={{ marginBottom: "6px" }} />
                                    </div>
                                </div>
                                {search(rowAvailableSites).length > 0 ? <DataTable
                                    columns={columnsAvlSites}
                                    data={search(rowAvailableSites)}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    sortFunction={props.sortFunctionByTable}
                                    selectableRowsNoSelectAll="false"
                                    highlightOnHover
                                    striped
                                    pagination
                                /> : <NoData headers={columnsAvlSites}></NoData>}
                            </div>
                        </div>
                        <div className="col-sm-2 d-flex align-items-center justify-content-center" style={{ width: "8%" }}>
                            <table style={{ border: "none", marginTop: "75px" }}>
                                <tr>
                                    <td>
                                        {usrRole != "Read-Only" && <img src={ArrowRight} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt=">>" onClick={handleRightShift} />}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {usrRole != "Read-Only" && <img src={ArrowLeft} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt="<<" onClick={handleLeftShift} />}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-sm-5" style={{ width: "46%" }}>
                            <div className="tableContainer">
                                <div className="row">
                                    <div className="col-sm-6 d-flex align-items-center" style={{ textAlign: "left" }}>
                                        <h5><strong>Assigned Sites</strong></h5>
                                    </div>
                                    <div className="col-sm-6">

                                    </div>
                                </div>
                                {rowUserAssignedSites.length > 0 ? <DataTable
                                    columns={columnsAgnSites}
                                    data={rowUserAssignedSites}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    sortFunction={props.sortFunctionByTable}
                                    selectableRowsNoSelectAll="false"
                                    striped
                                    pagination
                                /> : <NoData headers={columnsAgnSites}></NoData>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" className="btn btn-success" onClick={handleClose} style={{ display: "none" }} > Submit </Button>
                    <Button variant="primary" className="btn btn-danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AssignSiteModal;