import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import CopyIcon from '../../Images/Icons/copy.svg';
import DeleteIcon from '../../Images/Icons/delete.svg';
import ParadigmDesignerModel from './ParadigmDesignerModel';
import customStyles from '../customStyles';
import NoData from '../NoData';

const Paradigms = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const navigate = useNavigate();
    const onClickHandler = () => {
        navigate('/ParadigmDesigner');
    }
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [paradigmData, setParadigmData] = useState([]);
    const setCompPermission = (usrRole) => {
        if (usrRole == "Read-Only")
            setUserPermisssion("RO")
        else if (usrRole == "Tester")
            setUserPermisssion("RO")
        else if (usrRole == "Database Designer" || usrRole == "Super User")
            setUserPermisssion("RW")
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management") {
            setUserPermisssion("RO")
        }
        else
            setUserPermisssion("NA")
    }
    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setCompPermission(role);
            });
    };

    const columns = [
        {
            name: "",
            selector: row => row.ParadigmID,
            cellExport: row => row.ParadigmID,
            omit: true
        },
        {
            name: "Name",
            selector: row => row.Name,
            cellExport: row => row.Name,
            sortable: true,
            center: true,
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.Name, b.Name); }
        },
        {
            name: "Description",
            selector: row => row.Description,
            cellExport: row => row.Description,
            sortable: true,
            center: true,
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.Description, b.Description); }
        },
        {
            name: "Number of Stages",
            selector: row => row.Stages,
            cellExport: row => row.Stages,
            sortable: true,
            center: true
        },
        {
            name: "Creation User",
            selector: row => row.Username,
            cellExport: row => row.Username,
            sortable: true,
            center: true,
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.Username, b.Username); }
        },
        {
            name: "Creation Date",
            selector: row => row.CurrentDate,
            cellExport: row => row.CurrentDate,
            sortable: true,
            center: true,
            sortFunction: (a, b) => { return props.sortByDateTime(a.CurrentDate, b.CurrentDate); }
        },        
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '550px',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {userPermission == "RW" && <ParadigmDesignerModel action="view" paradigmID={row.ParadigmID} header="Paradigm Designer in view mode" />}
                    {userPermission == "RW" && row.IsParadigmUsed == false && <ParadigmDesignerModel action="edit" paradigmID={row.ParadigmID} header="Paradigm Designer in edit mode" fetchParadigmsData={fetchParadigmsData} />}
                    {userPermission == "RW" && row.IsParadigmUsed == false && <img style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "5px", "float": "right" }} height="34px" width="34px" src={DeleteIcon} alt="Delete" onClick={e => DeleteRow(row, e)} />}
                    {userPermission == "RW" && <img style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "5px", "float": "right" }} height="34px" width="34px" src={CopyIcon} alt="Copy" onClick={e => CopyRow(row, e)} />}

                    {userPermission == "RO" && <ParadigmDesignerModel action="view" paradigmID={row.ParadigmID} header="Paradigm Designer in view mode" />}
                </div>
            )
        }
    ];
    const filteredColumns = ['Name', 'Description', 'Stages', 'Username', 'CurrentDate'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
            })
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
        document.getElementById("ddlStudy").selectedIndex = 0;
    }

    const fetchParadigmsData = () => {
        fetch('api/Paradigms/GetParadigms')
            .then(response => response.json())
            .then(data => {
                setParadigmData(data);
            });
    }
    useEffect(() => {
        fetchParadigmsData();
        getUserRole ();
    }, []);

    const CopyRow = (eventRow, event) => {
        var con = confirm('Are you sure, you want to copy "' + eventRow.Name + '" paradigms?');
        if (con == true) {
            let paradigmsModel =
            {
                ParadigmID: eventRow.ParadigmID,
                Username: EDCUserID,
            }

            fetch('api/Paradigms/CopyParadigms', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(paradigmsModel)
            }).then(r => r.text()).then(res => {
                if (res == -1 || res == 0) {
                    alert('Paradigms not copied, Something went wrong.');
                    return false;
                }
                if (res == 1) {
                    fetchParadigmsData();
                }
            })
            return true;
        }
    };


    const DeleteRow = (eventRow, event) => {
        var con = confirm('Are you sure, you want to delete "' + eventRow.Name + '" paradigms?');
        if (con == true) {
            let paradigmsModel =
            {
                ParadigmID: eventRow.ParadigmID,
                Alert: 'X',
                Username: EDCUserID,
            }

            fetch('api/Paradigms/DeleteParadigms', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(paradigmsModel)
            }).then(r => r.text()).then(res => {
                if (res == -1 || res == 0) {
                    alert('Paradigms not deleted, Something went wrong.');
                    return false;
                }
                if (res == 1) {
                    fetchParadigmsData();
                }
            })
            return true;
        }
    };


    return <Card>
        <Row>
            <Col md={4} className="form-inline mb-2">
            </Col>
            <Col md={6}>
                <div className="form-inline mb-2">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
            <Col md={2}>
                {userPermission == "RW" && <Button style={{ "float": "right" }} onClick={onClickHandler} className="btn btn-success">Add New Paradigm</Button>}
            </Col>
        </Row>
        <div className="tableContainer">
            {search(paradigmData).length > 0 ? <DataTable
                columns={columns}
                data={search(paradigmData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}
export default Paradigms;