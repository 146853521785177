import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import SelectList from '../UI/Control/SelectList';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';
import Button from '..//UI/Button/Button';

const GlobalReportViewer = (props) => {
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const colum = new Array();
    const [filteredColumns, setfilteredColumns] = useState([]);
    const [arrRptCategory, setArrRptCategory] = useState([]);
    const [arrReportName, setArrReportName] = useState([]);
    const [fileName, setFileName] = useState('GlobalReportViewer');

    function search(rows) {
        let selString = inputVal.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1) {
                    if (row[column] != null) {
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    }
                }
                else {
                    if (row[column] != null) {
                        return row[column].toString().toLowerCase().indexOf(inputVal.toLowerCase()) > -1
                    }
                }
            })
        })
    }

    const searchChangeHandler = (e) => {
        setInputVal(e.target.value);
    }

    const fetchReportCategoryList = () => {
        fetch('api/Report/FillReportCategoryList')
            .then(response => response.json())
            .then(data => {
                setArrRptCategory(data);
            });
    }

    const fetchReportNameList = (reportCategory) => {
        fetch('api/Report/FillReportNameList?reportCategory='+ reportCategory)
            .then(response => response.json())
            .then(data => {
                setArrReportName(data);
            });
    }

    const fetchGlobalReportList = (reportId, arrHiddenColumn) => {
        var parameter = new Array();
        var dataType = '';
        var parameterName = '';
        var parameterValue = '';
        var controlType = '';
        let objParamaters = document.getElementsByClassName('cssParameter');
        for (var cnt = 0; cnt < objParamaters.length; cnt++) {

            parameterName = objParamaters[cnt].getAttribute('parameter');
            parameterValue = objParamaters[cnt].value.trim();
            dataType = objParamaters[cnt].getAttribute('dataType');
            controlType = objParamaters[cnt].getAttribute('controltype');

            if (objParamaters[cnt].getAttribute('ismandatory') == 'Y') {
                if (parameterValue == '') {
                    alert('"' + parameterName + '" parameter value should not be empty.');
                    objParamaters[cnt].focus();
                    return false;
                }

                if (parameterValue == '-1') {
                    alert('Please select "' + parameterName + '" parameter value.');
                    objParamaters[cnt].focus();
                    return false;
                }
            }

            if (dataType == 'int' && controlType != 'select' && !/^[0-9]+$/.test(parameterValue)) {
                alert('Please enter numeric value for "' + parameterName + '" parameter!');
                objParamaters[cnt].focus();
                return false;
            }

            //if (parameterValue != '' && controlType != 'select' && !parameterValue.match("^[A-Za-z0-9? ,_-]+$")) {
            //    alert('Please enter alpha numeric value for "' + parameterName + '" parameter!');
            //    objParamaters[cnt].focus();
            //    return false;
            //}

            parameter.push({ Name: parameterName, Value : parameterValue });
        }

        let model = {
            reportId: reportId,
            listParameter: parameter
        };

        let searchColumn = new Array();
        fetch('api/Report/GetGlobalReportList', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(model)
        }).then(r => r.json()).then(data => {
            if (data != "-1") {
                for (let column in data[0]) {
                    if (arrHiddenColumn.indexOf(column) == -1) {
                        if (typeof data[0][column] == 'string') {
                            if (column.toLowerCase().indexOf("date") > -1)
                                colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true, wrap: true, sortFunction: (a, b) => { return props.sortByDateTime(a[column], b[column]); } });
                            else
                                colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true, wrap: true, sortFunction: (a, b) => { return props.sortFunctionByColumn(a[column], b[column]); } });
                        }
                            else 
                                colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true, wrap: true });
                           
                            searchColumn.push(column);
                        }
                    }
                    setColumns(colum);
                    setRows(data);
                    setfilteredColumns(searchColumn);
                }
                else
                    alert("Error while generating global report viewer, contact system admin.")
            });
    }

    const onRptCategoryChangeHandler = (e) => {
        setColumns([]);
        setRows([]);
        setfilteredColumns([]);
        document.getElementById('divParameter').style.display = 'none';
        document.getElementById('divParameter').innerHTML = '';
        let objRptCategory = document.getElementById("ddlReportCategory");
        document.getElementById("ddlReportName").value = -1;
        fetchReportNameList(objRptCategory.value, '-1');
    }

    const LoadReport = (e) => {
        let objReportName = document.getElementById("ddlReportName");
        if (objReportName.value == '-1') {
            alert('Please select report name.');
            objReportName.focus();
            return false;
        }
        var selectedReportName = objReportName.options[objReportName.selectedIndex].text;
        setFileName(selectedReportName);
        LoadHiddenColumns(objReportName.value);
    }

    function LoadReportParameters(reportId) {
        document.getElementById('divParameter').style.display = 'none';
        document.getElementById('divParameter').innerHTML = '';
        if (reportId > 0) {
            let select = '';
            document.getElementById('divParameter').style.display = 'block';
            fetch('api/Report/GetReportParameters?reportId=' + reportId)
                .then(response => response.text())
                .then(data => {
                    if (data != "-1") {
                        var arrParameter = JSON.parse(data); 
                        var newTextBoxDiv = document.createElement('div');
                        newTextBoxDiv.setAttribute("class", 'jumbotron');
                        for (var iCount = 0; iCount < arrParameter.length; iCount++) {
                            if (arrParameter[iCount].ControlType == 'hidden') {
                                newTextBoxDiv.innerHTML += '<input type="hidden" class="cssParameter" id="hdn_' + arrParameter[iCount].Name + '" parameter="' + arrParameter[iCount].Name.replace('@', '').trim() + '"> ';
                            }
                            else {
                                newTextBoxDiv.setAttribute("id", 'TextBoxDiv' + iCount);
                                if (arrParameter[iCount].ControlType == 'text') {
                                    newTextBoxDiv.innerHTML += '<label>' + arrParameter[iCount].Name.replace('@', '') + ' : </label>';
                                    if (arrParameter[iCount].IsMandatory == 'Y') {
                                        newTextBoxDiv.innerHTML += '<label style="color:red;">*</label> ';
                                    }
                                    newTextBoxDiv.innerHTML += ' <input type="text" class="cssParameter form-control" name="txtParameter' + iCount +
                                        '" style="width:12%;display:inline;margin-bottom:10px;" id="txt_' + arrParameter[iCount].Name + '" controlType = "text" dataType="' + arrParameter[iCount].DataType + '" ismandatory="' + arrParameter[iCount].IsMandatory + '" parameter="' + arrParameter[iCount].Name.replace('@', '').trim() + '" value="" > ';
                                }

                                if (arrParameter[iCount].ControlType == 'select') {
                                    newTextBoxDiv.innerHTML += '<label>' + arrParameter[iCount].Name.replace('@', '') + ' : </label>';
                                    if (arrParameter[iCount].IsMandatory == 'Y') {
                                        newTextBoxDiv.innerHTML += '<label style="color:red;">*</label> ';
                                    }
                                    select += '<select id="ddl_' + arrParameter[iCount].Name + '" ismandatory="' + arrParameter[iCount].IsMandatory + '" controlType = "select" dataType="' + arrParameter[iCount].DataType + '" parameter="' + arrParameter[iCount].Name.replace('@', '').trim() + '" class="cssParameter form-select" style="width:15%;display:inline;margin-bottom:10px;">';
                                    let objSelect = JSON.parse(arrParameter[iCount].SelectList);
                                    for (var jCount = 0; jCount < objSelect.length; jCount++) {
                                        select += '<option value="' + objSelect[jCount].Value + '">' + objSelect[jCount].Name + '</option>';
                                    }

                                    select += '</select>';

                                    newTextBoxDiv.innerHTML = newTextBoxDiv.innerHTML + select;
                                }

                                if ((iCount + 1) % 4 == 0) {
                                    newTextBoxDiv.innerHTML += "</br>";
                                }
                            }
                        }
                        document.getElementById('divParameter').innerHTML = '<label style="font-weight:bold;">Parameters : </label> </br>'+ newTextBoxDiv.innerHTML;
                    }
                    else
                        alert("Error while loading report parameter, contact system admin.")
                });
        }
    }

    function LoadHiddenColumns(reportId) {
        var arrHiddenColumn = '';
        fetch('api/Report/GetHiddenColumns?reportId=' + reportId, {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: ''
            }).then(response => response.text())
            .then(data => {
                if (data != "-1" || data != "") {
                    arrHiddenColumn = data.split(',');
                    fetchGlobalReportList(reportId, arrHiddenColumn);
                }
                else
                    alert("Error while loading hidden column, contact system admin.")
            });
    }

    const reportNameChangeHandler = (e) => {
        setColumns([]);
        setRows([]);
        setfilteredColumns([]);
        LoadReportParameters(e.target.value);
    }

    useEffect(() => {
        fetchReportCategoryList();
    }, []);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={2}>
                <div className="input-group">
                    <SelectList id='ddlReportCategory' dataSource={arrRptCategory} onChangeHandler={onRptCategoryChangeHandler} />
                </div>
            </Col>
            <Col md={2}>
                <div className="input-group">
                    <SelectList id='ddlReportName' dataSource={arrReportName} onChangeHandler={reportNameChangeHandler} />
                </div>
            </Col>
            <Col md={2}>
                <Button id="btnLoadReport" className="btn btn-success" style={{ marginRight: "10px" }} onClick={LoadReport}> Load Report </Button>
            </Col>
            <Col md={2}>
            </Col>
            <Col md={4}>
                <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
            </Col>

        </Row>
        <div class="row">
            <div class="col-sm-12">
                <div id='divParameter' style={{ backgroundColor: "antiquewhite", padding: "10px", borderStyle: "solid", borderWidth: "1px", borderColor: "silver", display:"none" }}>
                </div>
            </div>
        </div>
        <div className="tableContainer" style={{ overflowX: "auto", overflowY: "hidden", width: "100%", maxWidth: "100%", display: "block", height:"auto" }}>
            {search(rowData).length > 0 ?
                <DataTableExtensions columns={columns} data={rowData} filter={false} exportHeaders={true} fileName={fileName}>
                    <DataTable
                        columns={columns}
                        data={search(rowData)}
                        className="table table-striped table-bordered table-hover"
                        customStyles={customStyles}
                        striped
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[20, 50, 100]}
                    />
                </DataTableExtensions> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}

export default GlobalReportViewer;