import React, { useState } from "react";
import { useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import InputString from '../UI/Control/InputString';
import ArrowLeft from '../../Images/Icons/ArrowLeftBorder.svg';
import ArrowRight from '../../Images/Icons/ArrowRightBorder.svg';
import customStyles from '../customStyles';
import NoData from '../NoData';
import { Modal } from 'react-bootstrap';
import CopyIcon from '../../Images/Icons/copy.svg';
import classes from './../TrialstatNoto.module.css';

const AssignUserModal = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [show, setShow] = useState(false);
    const [usrRole, setUsrRole] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => {
        fetchAvailableUsers();
        fetchAssignedUsersToStudy();
        setShow(true);
        getUserRole();
    };

    const [q, setQ] = useState('');

    const [rowAvailableUsers, setAvailableUsers] = useState([]);
    const [rowAssignedUsers, setAssignedUsers] = useState([]);
    const [inputVal, setInputVal] = useState('');

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setUsrRole(role);
            });
    };

    const columnsAvlUser = [
        {
            name: "",
            width: "50px",
            cell: (row) => (
                <input
                    type="checkbox"
                    className="chkClassName"
                    onClick={() => handleAvailableUserSelectRow(row.EDCUserID, event)}
                    style={{ height: '20px', width: '20px' }}
                />
            )
        },
        {
            name: "Name",
            selector: row => row.FullName,
            cellExport: row => row.FullName,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
        },
        {
            name: "Email",
            selector: row => row.Email,
            cellExport: row => row.Email,
            sortable: true,
            center: true
        }
    ];

    const columnsAgnUser = [
        {
            name: "",
            width: "50px",
            cell: (row) => (
                <input
                    type="checkbox"
                    className="chkRClassName"
                    onClick={() => handleAssignedUserSelectRow(row.EDCUserID, event)}
                    style={{ height: '20px', width: '20px' }}
                />
            )
        },
        {
            name: "Name",
            selector: row => row.FullName,
            cellExport: row => row.FullName,
            sortable: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
        },
        {
            name: "Assigned Role",
            center: true,
            cell: (row) => {
                return <div style={{ width: "100%" }}>
                    <input type="hidden" id={"hdnEDCUserId_" + row.UserID} value={row.EDCUserID} />
                    <input type="hidden" id={"hdnRoleName_" + row.UserID} value={row.RoleLabel} />
                    {props.userPermission == "RW" && <SelectList id={"ddlAssignRole_" + row.UserID} value={row.RoleID} dataSource={props.arrAssignedRole} onChangeHandler={roleChangeHandler} style={{ margin: "5px", width: "90%" }} disabled="disabled" />}
                    {props.userPermission == "RO" && <SelectList id={"ddlAssignRole_" + row.UserID} disabled="disabled" value={row.RoleID} dataSource={props.arrAssignedRole} style={{ margin: "5px", width: "90%" }} />}
                    {props.userPermission == "RW" && (row.RoleID == 5 || row.RoleID == 6) && <SelectList id={"ddlImgSerRole_" + row.UserID} value={row.ReadTypes.ReadTypesID} dataSource={props.arrImgServRole} onChangeHandler={crRoleChangeHandler} style={{ margin: "5px", width: "90%" }} disabled="disabled" />}

                </div>
            }
        },
    ];

    const filteredColumns = ['FullName', 'Email'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
            })
        })
    }

    var arrAvlEDCUserId = new Array();
    const handleAvailableUserSelectRow = (state, event) => {
        if (event.target.checked == true) {
            arrAvlEDCUserId.push(state);
        } else {
            arrAvlEDCUserId = arrAvlEDCUserId.filter(item => item !== state);
        }
    };

    var arrAssEDCUserId = new Array();
    const handleAssignedUserSelectRow = (state, event) => {
        if (event.target.checked == true) {
            arrAssEDCUserId.push(state);
        }
        else {
            arrAssEDCUserId = arrAssEDCUserId.filter(item => item !== state);
        }
    };

    const roleChangeHandler = (e) => {
        var corImgSerId = 5;
        var mgtImgSerId = 6;
        let arr = e.target.id.split('_');
        let userId = arr[1];
        let UserIDTemp = document.getElementById('hdnEDCUserId_' + userId).value;
        let RoleName = document.getElementById('hdnRoleName_' + userId).value;

        let roleId = e.target.value;
        var objSelect = document.getElementById(e.target.id);
        var roleLabel = objSelect.options[objSelect.selectedIndex].text;

        if (roleId == corImgSerId || roleId == mgtImgSerId) {
            if (document.getElementById('ddlImgSerRole_' + userId) != null) {
                document.getElementById('ddlImgSerRole_' + userId).style.display = 'block';
            }
        }
        else {
            if (document.getElementById('ddlImgSerRole_' + userId) != null) {
                document.getElementById('ddlImgSerRole_' + userId).style.display = 'none';
            }
        }

        let objUsersModel = {
            Username: EDCUserID,
            EDCUserID: UserIDTemp,
            RoleID: roleId,
            RoleLabel: roleLabel,
            ReadTypes: { ReadTypesID: 0 }
        };
        fetch('api/Users/UpdateUserRole', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUsersModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('User role not updated, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchAssignedUsersToStudy();
                if (roleId != corImgSerId && roleId != mgtImgSerId) {
                    alert('Updated user role from ' + RoleName + ' to ' + roleLabel);
                }
            }
        })
    }

    const crRoleChangeHandler = (e) => {
        if (e.target.value == 0) {
            alert('Reader type should not be empty.');
            e.target.focus();
            return false;
        }
        let arr = e.target.id.split('_');
        let userId = arr[1];
        let RoleName = document.getElementById('hdnRoleName_' + userId).value;
        let UserIDTemp = document.getElementById('hdnEDCUserId_' + userId).value;

        let readTypesID = e.target.value;
        var objSelect = document.getElementById(e.target.id);
        var roleLabel = objSelect.options[objSelect.selectedIndex].text;
        let roleId = document.getElementById('ddlAssignRole_' + userId).value;

        let objUsersModel = {
            Username: EDCUserID,
            EDCUserID: UserIDTemp,
            RoleID: roleId,
            RoleLabel: roleLabel,
            ReadTypes: { ReadTypesID: readTypesID }
        };
        fetch('api/Users/UpdateUserRole', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUsersModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('User role not updated, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchAssignedUsersToStudy();
                alert('Updated user role ‘' + RoleName + '’ with reader type: ' + roleLabel + '.');
            }
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const AssignUserToStudy = (event, arrAvlEDCUserId) => {
        let objUserStudyAssignments = {
            Username: EDCUserID,
            StudyID: props.studyId,
            listEDCUserID: new Array()
        }

        for (var iCount = 0; iCount < arrAvlEDCUserId.length; iCount++) {
            objUserStudyAssignments.listEDCUserID.push(arrAvlEDCUserId[iCount]);
        }

        fetch('api/Users/AssignUserToStudy', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUserStudyAssignments)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Study is not assigned to user, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchAvailableUsers();
                fetchAssignedUsersToStudy();
            }
        })
        return true;
    };

    const RemoveUserFromStudy = (event, arrAssEDCUserId) => {
        let objUserStudyAssignments = {
            Username: EDCUserID,
            StudyID: props.studyId,
            listEDCUserID: new Array()
        }

        for (var iCount = 0; iCount < arrAssEDCUserId.length; iCount++) {
            objUserStudyAssignments.listEDCUserID.push(arrAssEDCUserId[iCount]);
        }

        fetch('api/Users/RemoveUserFromStudy', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUserStudyAssignments)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('User is not removed from study, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchAvailableUsers();
                fetchAssignedUsersToStudy();
            }
        })
        return true;
    };

    const handleRightShift = (e) => {
        let arrChk = document.getElementsByClassName('chkClassName');
        for (var iCount = 0; iCount < arrChk.length; iCount++) {
            arrChk[iCount].checked = false;
        }

        if (arrAvlEDCUserId.length == 0) {
            alert('Please select user(s) to assign study.');
        }
        else {
            AssignUserToStudy(e, arrAvlEDCUserId);
        }
    };

    const handleLeftShift = (e) => {
        let arrChk = document.getElementsByClassName('chkRClassName');
        for (var iCount = 0; iCount < arrChk.length; iCount++) {
            arrChk[iCount].checked = false;
        }

        if (arrAssEDCUserId.length == 0) {
            alert('Please select user(s) to revoke from study.');
        }
        else {
            RemoveUserFromStudy(e, arrAssEDCUserId);
        }
    };

    const fetchAvailableUsers = () => {
        fetch('api/Users/GetAvailableUsers?studyId=' + props.studyId)
            .then(response => response.json())
            .then(data => {
                setAvailableUsers(data);
            });
    }

    const fetchAssignedUsersToStudy = () => {
        fetch('api/Users/GetAssignedUsersToStudy?studyId=' + props.studyId)
            .then(response => response.json())
            .then(data => {
                setAssignedUsers(data);
            });
    }


    return (
        <>
            <img className="" style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "10px" }} height="34px" width="34px" src={CopyIcon} alt="Add" onClick={handleShow} />
            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Two} animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{props.studyName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-1">
                        <div className="col-sm-5" style={{ width: "46%" }}>
                            <div className="tableContainer">
                                <div className="row">
                                    <div className="col-sm-4 d-flex align-items-center" style={{ textAlign: "left" }}>
                                        <h5><strong>Available User</strong></h5>
                                    </div>
                                    <div className="col-sm-8">
                                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" style={{ marginBottom: "6px" }} />
                                    </div>
                                </div>
                                {search(rowAvailableUsers).length > 0 ? <DataTable
                                    columns={columnsAvlUser}
                                    data={search(rowAvailableUsers)}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    sortFunction={props.sortFunctionByTable}
                                    selectableRowsNoSelectAll="false"
                                    highlightOnHover
                                    striped
                                    pagination
                                /> : <NoData headers={columnsAvlUser}/>}
                            </div>
                        </div>
                        <div className="col-sm-2 d-flex align-items-center justify-content-center" style={{ width: "8%" }}>
                            <table style={{ border: "none", marginTop: "75px" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            {usrRole != "Read-Only" && <img src={ArrowRight} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt=">>" onClick={handleRightShift} />}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {usrRole != "Read-Only" && <img src={ArrowLeft} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt="<<" onClick={handleLeftShift} />}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-sm-5" style={{ width: "46%" }}>
                            <div className="tableContainer">
                                <div className="row">
                                    <div className="col-sm-6 d-flex align-items-center" style={{ textAlign: "left" }}>
                                        <h5><strong>Assigned User</strong></h5>
                                    </div>
                                    <div className="col-sm-6" />
                                </div>
                                {rowAssignedUsers.length > 0 ? <DataTable
                                    columns={columnsAgnUser}
                                    data={rowAssignedUsers}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    sortFunction={props.sortFunctionByTable}
                                    selectableRowsNoSelectAll="false"
                                    striped
                                    pagination
                                /> : <NoData headers={columnsAgnUser} />}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" className="btn btn-success" onClick={handleClose} style={{ display: "none" }} > Submit </Button>
                    <Button variant="primary" className="btn btn-danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AssignUserModal;