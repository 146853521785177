import React, { useState, useEffect} from "react";
import DataTable from "react-data-table-component";
import Button from '../UI/Button/Button';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import { useNavigate, useLocation } from 'react-router-dom';
import AssignSiteToCRUserModal from './AssignSiteToCRUserModal';
import classes from './../TrialstatNoto.module.css';

const CRUserList = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [q, setQ] = useState('');
    const [userCRType, setUserCRType] = useState('');
    const [userRole, setUserRole] = useState('');
    const [rowCRUsers, setCRUsers] = useState([]);
    const [inputVal, setInputVal] = useState('');

    let studyId = '';
    let studyName = '';
    if (location.state != null) {
        studyId = location.state.studyId;
        studyName = location.state.studyName;
    }

    const columnsCRUsers = [
        {
            name: "EDCUserID",
            selector: row => row.EDCUserID,
            omit: true
        },
        {
            name: "User Names",
            selector: row => row.UserName,
            sortable: true,
            center: true,
            width: '22%'
        },
        {
            name: "Full Names",
            selector: row => row.FullName,
            sortable: true,
            center: true,
            width:'22%'
        },      
        {
            name: "Email",
            selector: row => row.Email,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Assigned Sites",
            selector: row => row.Sites,
            sortable: true,
            center: true,
            cell: (row) => (
                <div className={classes.textWrap} title={row.Sites}>{row.Sites.trim().length > 100 ? row.Sites.trim().substring(0, 100)+'...' : row.Sites} </div>
            )
        },
        {
            name: "No Affiliation",
            selector: row => row.NoAffiliation,
            sortable: true,
            center: true,
            width: '10%',
            cell: (row) => (
                <p>
                    {row.NoAffiliation == 'AFF' && <input type="checkbox" className="chkClassName" onClick={() => onChangeHandler(row.EDCUserID, event)}
                        style={{ height: '20px', width: '20px' }} disabled />}
                    {row.NoAffiliation == 'NOAFF' && <input type="checkbox" className="chkClassName" onClick={() => onChangeHandler(row.EDCUserID, 0)}
                        style={{ height: '20px', width: '20px' }} checked/>}
                    {row.NoAffiliation == 'NOTSET' && <input type="checkbox" className="chkClassName" onClick={() => onChangeHandler(row.EDCUserID, 1)}
                        style={{ height: '20px', width: '20px' }}  />}
                </p>
            )
        },
        {
            name: "Action",
            button: true,
            width: (userRole == 'Management, Image Services' || userRole == 'Image Services Coordinator') ? '12%' : "0",
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {(userRole == 'Management, Image Services' || userRole == 'Image Services Coordinator') && (row.NoAffiliation == 'AFF' || row.NoAffiliation == 'NOTSET')
                        && <AssignSiteToCRUserModal style={{ "margin": "5px" }} text="Assign Site" header="Assign Site" studyId={studyId} edcUserID={row.EDCUserID}
                            fetchCRUsersByStudy={fetchCRUsersByStudy}></AssignSiteToCRUserModal>}
                </div >
            )
        }
    ];

    //EDCUserID = -1: Update "No Affiliation" at study level.
    const markAllNoAff = () => {
        onChangeHandler("-1", 1)
    }

    //Update "No Affiliation"
    const onChangeHandler = (EDCUserID, affFlag) => {
        fetch('api/SiteAffiliation/NoAffByUserID?studyId=' + studyId + '&userId=' + EDCUserID + "&affFlag=" + affFlag, {
            method: 'POST'
        })
        .then(response => response.text())
            .then(data => {
                if (data = 'success')
                    fetchCRUsersByStudy();
                else
                    alert('An error occurred while processing the request.')
            });
    } 

    useEffect(() => {
        getUserNameAndRoleByID();
        fetchCRUsersByStudy();
        setQ('');
        setInputVal('');
    }, []);

    const filteredColumns = ['FullName', 'Email', 'Sites'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (row[column] != null) {
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                }
            })
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const fetchCRUsersByStudy = () => {
        fetch('api/SiteAffiliation/GetCRUsersByStudy?studyId=' + studyId)
            .then(response => response.json())
            .then(data => {
                setCRUsers(data);
            });
    }

    const cancelCRUserList = () => {
        navigate('/CRSiteAffiliation');
    }

    const getUserNameAndRoleByID = () => {
        fetch('api/Users/getUserNameAndRoleByID')
            .then(response => response.json())
            .then(data => {
                setUserCRType(data.ReadType);
                setUserRole(data.RoleLabel);
            });
    };
       
    return (
        <div className="container" style={{ width: "90%", maxWidth: "90%" }}>
                    <div className="row mt-1 alert alert-success" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                <div className="col-sm-6 align-self-center">
                    <strong>Study:  </strong>{studyName}
                        </div>
                        <div className="col-sm-6">
                            <Button variant="primary" className="btn btn-success" style={{ float: "right", marginRight: "15px" }} onClick={cancelCRUserList}>Return To Site Affiliation</Button>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-sm-12" style={{ width: "100%" }}>
                            <div className="tableContainer">
                                <div className="row">
                                    <div className="col-sm-2 d-flex align-items-center" style={{ textAlign: "left" }}>
                                        <h5><strong>CR Users</strong></h5>
                                    </div>
                                    <div className="col-sm-4">
                                    <Button variant="primary" className="btn btn-success" style={{ float: "right", marginRight: "15px" }}
                                        onClick={markAllNoAff}>Mark All As No Affiliation</Button>
                                    </div>
                                    <div className="col-sm-6">
                                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" style={{ marginBottom: "6px" }} />
                                    </div>
                                </div>
                        {search(rowCRUsers).length > 0 ? <DataTable
                            columns={columnsCRUsers}
                            data={search(rowCRUsers)}
                            className="table table-striped table-bordered table-hover"
                            customStyles={customStyles}
                            sortFunction={props.sortFunctionByTable}
                            selectableRowsNoSelectAll="false"
                            striped
                            pagination
                        /> : <NoData headers={columnsCRUsers}></NoData>}
                            </div>
                        </div>
            </div>
        </div>               
    );
}
export default CRUserList;