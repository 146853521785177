import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import SelectList from '../UI/Control/SelectList';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';
import QueryAddEditModal from './QueryAddEditModal';
import AddNewQueryModal from './AddNewQueryModal';
import MultiSelectList from '../UI/Control/MultiSelectList';

const ISCQueryDashboard = (props) => {
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const colum = new Array();
    const [filteredColumns, setfilteredColumns] = useState([]);
    const [studyList, setStudyList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [studyID, setStudyID] = useState('');
    const [arrQueryStatus, setQueryStatus] = useState([]);
    const [selectedQStusIds, setSelectedQStusIds] = useState('');

    function search(rows) {
        let selString = inputVal.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1) {
                    if (row[column] != null) {
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    }
                }
                else {
                    if (row[column] != null) {
                        return row[column].toString().toLowerCase().indexOf(inputVal.toLowerCase()) > -1
                    }
                }
            })
        })
    }

    const searchChangeHandler = (e) => {
        //setDMVarName(e.target.value);
        setInputVal(e.target.value);
    }

    const fetchQueryDashboardData = (inQueryStatusId) => {
        colum.push({
            name: "Action",
            button: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
                'width': '7%'
            },
            cell: (row) => (
                <div>
                    <QueryAddEditModal UserRole="ImageService" queryid={row.QueryID} Title="Query Details" btnText="Action" refreshsata={fetchQueryDashboardData}/>
                </div>
            )
        });
        let searchColumn = new Array();
        fetch('api/ImageService/GetQueryDashboardData?studyid=' + studyID + '&QueryStatusId=' + inQueryStatusId)
            .then(response => response.json())
            .then(data => {
                for (let column in data[0]) {
                    if (typeof data[0][column] == 'string') {
                        if (column.toLowerCase().indexOf("date") > -1)
                            colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true, sortFunction: (a, b) => { return props.sortByDateTime(a[column], b[column]); } });
                        else
                            colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true, sortFunction: (a, b) => { return props.sortFunctionByColumn(a[column], b[column]); } });
                    }
                    else
                        colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true });

                    searchColumn.push(column);
                }
                setColumns(colum);
                setRows(data);
                setfilteredColumns(searchColumn);
            });
    }
    const studyOnChangeHandler = (e) => {
        setInputVal("");
        setStudyID(e.target.value);
    }

    const fetchStudies = () => {
        fetch('api/ImageService/FillQueryDashboardStudyList')
            .then(response => response.json())
            .then(data => {

                setStudyList(data);
                //var element = document.getElementById("ddlStudy");                
                //if (element.options[0].value == "-1") {
                //    element.remove(0);
                //}
                var selStudyID = document.getElementById("ddlStudy").value;
                setStudyID(selStudyID);
                FillQueryStatus();
                GetSetStausFilter();
                fetchQueryDashboardData();
            });
    };

    const GetSetStausFilter = () => {
        let multiqSelect = document.getElementById('lstISCQueryStatus');
        let getFill = '', optText = '';
        
        for (var cntSts = 0; cntSts < multiqSelect.length; cntSts++) {
            optText = multiqSelect[cntSts].text.toUpperCase();

            if (optText == 'OPEN' || optText == 'RESPONDED') {
                getFill = getFill + multiqSelect[cntSts].value + ",";
            }
        }
        if (getFill.endsWith(","))
            getFill = getFill.substr(0, getFill.length - 1);

        setSelectedQStusIds(getFill);
    };

    const FillQueryStatus = () => {
        fetch('api/ImageService/FillQueryStatus')
            .then(response => response.json())
            .then(data => {
                setQueryStatus(data);
            });
    };
    useEffect(() => {
        fetchStudies();
    }, [studyID]);
    const iscQueryStatusOnChange = (e) => {
        let multiSelect = document.getElementById('lstISCQueryStatus');
        let multiSelected = [...multiSelect.selectedOptions].map(option => option.value);
        let selStatusIds = '';

        for (var jCount = 0; jCount < multiSelected.length; jCount++) {
            selStatusIds = selStatusIds + multiSelected[jCount] + ",";
        }
        if (selStatusIds.endsWith(","))
            selStatusIds = selStatusIds.substr(0, selStatusIds.length - 1);
        setSelectedQStusIds(selStatusIds);
        fetchQueryDashboardData(selStatusIds);
    };
    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={2}>
                <MultiSelectList id="lstISCQueryStatus" dataSource={arrQueryStatus} isMultiSelect="true" selectedData={selectedQStusIds} onChangeHandler={iscQueryStatusOnChange} size="3" style={{ width: "85%" }} />
            </Col>
            <Col md={3}>
                <SelectList id='ddlStudy' dataSource={studyList} onChangeHandler={studyOnChangeHandler} />
            </Col>
            <Col md={5}>
                {/* <SelectList id='ddlSite' dataSource={siteList} onChangeHandler={siteOnChangeHandler} style={{ width: "300px" }}  /> */}
                <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
            </Col>
            <Col md={1} />
            <Col md={1}>
                <AddNewQueryModal refreshsata={fetchQueryDashboardData} />
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ?
                <DataTableExtensions columns={columns} data={search(rowData)} filter={false}>
                    <DataTable
                        columns={columns}
                        data={search(rowData)}
                        className="table table-striped table-bordered table-hover"
                        customStyles={customStyles}
                        striped
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[20, 50, 100]}
                    />
                </DataTableExtensions> : <NoData headers={columns} />
            }
        </div>
    </Card>
};

export default ISCQueryDashboard;